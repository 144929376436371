export const USERMEMBERSHIP_CREATE_REQUEST = 'USERMEMBERSHIP_CREATE_REQUEST'
export const USERMEMBERSHIP_CREATE_SUCCESS = 'USERMEMBERSHIP_CREATE_SUCCESS'
export const USERMEMBERSHIP_CREATE_FAIL = 'USERMEMBERSHIP_CREATE_FAIL'
export const USERMEMBERSHIP_CREATE_RESET = 'USERMEMBERSHIP_CREATE_RESET'
export const USERMEMBERSHIP_DETAILS_REQUEST = 'USERMEMBERSHIP_DETAILS_REQUEST'
export const USERMEMBERSHIP_DETAILS_SUCCESS = 'USERMEMBERSHIP_DETAILS_SUCCESS'
export const USERMEMBERSHIP_DETAILS_FAIL = 'USERMEMBERSHIP_DETAILS_FAIL'
export const USERMEMBERSHIP_PAY_REQUEST = 'USERMEMBERSHIP_PAY_REQUEST'
export const USERMEMBERSHIP_PAY_SUCCESS = 'USERMEMBERSHIP_PAY_SUCCESS'
export const USERMEMBERSHIP_PAY_FAIL = 'USERMEMBERSHIP_PAY_FAIL'
export const USERMEMBERSHIP_PAY_RESET = 'USERMEMBERSHIP_PAY_RESET'
export const USERMEMBERSHIP_LIST_MY_REQUEST = 'USERMEMBERSHIP_LIST_MY_REQUEST'
export const USERMEMBERSHIP_LIST_MY_SUCCESS = 'USERMEMBERSHIP_LIST_MY_SUCCESS'
export const USERMEMBERSHIP_LIST_MY_FAIL = 'USERMEMBERSHIP_LIST_MY_FAIL'
export const USERMEMBERSHIP_LIST_MY_RESET = 'USERMEMBERSHIP_LIST_MY_RESET'
export const USERMEMBERSHIP_UPDATE_REQUEST = 'USERMEMBERSHIP_UPDATE_REQUEST'
export const USERMEMBERSHIP_UPDATE_SUCCESS = 'USERMEMBERSHIP_UPDATE_SUCCESS'
export const USERMEMBERSHIP_UPDATE_FAIL = 'USERMEMBERSHIP_UPDATE_FAIL'
export const USERMEMBERSHIP_LIST_ORG_REQUEST = 'USERMEMBERSHIP_LIST_ORG_REQUEST'
export const USERMEMBERSHIP_LIST_ORG_SUCCESS = 'USERMEMBERSHIP_LIST_ORG_SUCCESS'
export const USERMEMBERSHIP_LIST_ORG_FAIL = 'USERMEMBERSHIP_LIST_ORG_FAIL'
export const USERMEMBERSHIP_LIST_ORG_RESET = 'USERMEMBERSHIP_LIST_ORG_RESET'
