import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Container,
	Table,
	Button,
	Card,
	Col,
	Row,
	Modal,
	Form,
} from 'react-bootstrap'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import OrgAdminNav from '../../../components/OrgAdminNav/OrgAdminNav'
import { getUserDetails } from '../../../services/actions/userActions'
import { getOrgDetails } from '../../../services/actions/orgActions'
import {
	listOrgUserMemberships,
	createUserMembership,
} from '../../../services/actions/userMembershipActions'
import './OrgManageRolesScreen.css'
import { USERMEMBERSHIP_CREATE_RESET } from '../../../services/constants/userMembershipConstants'

const OrgManageRolesScreen = ({ match, history }) => {
	const dispatch = useDispatch()
	const orgSlug = match.params.slug

	const [showModal, setShowModal] = useState(false)
	const userMembershipCreate = useSelector(
		(state) => state.userMembershipCreate
	)
	const { success: userMembershipCreateSuccess } = userMembershipCreate

	const orgDetails = useSelector((state) => state.orgDetails)
	const {
		org,
		org: { roles },
	} = orgDetails

	const [newMember, setNewMember] = useState({
		membershipProgram: '',
		fullName: '',
		email: '',
		phone: '',
		dob: '',
		tShirtSize: '',
		totalAmountPaid: '',
		paymentMethod: '',
		membershipStatus: 'active',
		origin: 'manual',
		org: org?._id,
	}) // State for form inputs

	// User login details from state
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo: userCredInfo } = userLogin

	// User details from state
	const { user } = useSelector((state) => state.userDetails)

	const userMembershipListOrg = useSelector(
		(state) => state.userMembershipListOrg
	)
	const { userMemberships } = userMembershipListOrg

	useEffect(() => {
		if (!org?._id || org?.slug !== orgSlug) {
			dispatch(getOrgDetails(orgSlug))
		}
		if (org?._id && org?.slug === orgSlug) {
			dispatch(listOrgUserMemberships(org._id))
		}
	}, [orgSlug, org, dispatch])

	useEffect(() => {
		if (!userCredInfo) {
			history.push(`/login?redirect=org/${orgSlug}/manage`)
		}
		if (!user?._id) {
			dispatch(getUserDetails('profile'))
		}
		if (user?._id) {
			const userMembership = user?.orgRoles?.find(
				(role) => role.orgId == org?._id
			)
			const myUserType = userCredInfo.isAdmin
				? 'admin'
				: userMembership?.roleType

			if (!(myUserType === 'admin' || myUserType === 'manager')) {
				history.push('/')
			}
		}
	}, [dispatch, history, userCredInfo, user, org])

	// Reload members list when a new member is added
	useEffect(() => {
		if (userMembershipCreateSuccess) {
			dispatch(listOrgUserMemberships(org._id))

			const timer = setTimeout(() => {
				dispatch({ type: USERMEMBERSHIP_CREATE_RESET })
			}, 5000)

			return () => clearTimeout(timer) // Cleanup timer on component unmount
		}
	}, [userMembershipCreateSuccess])

	const handleCloseModal = () => {
		setErrorMessage('')
		setShowModal(false)
		setNewMember({
			fullName: '',
			email: '',
			phone: '',
			dob: '',
			tShirtSize: '',
			totalAmountPaid: '',
			paymentMethod: '',
			membershipStatus: 'active',
			origin: 'manual',
			org: org?._id,
		})
	}
	const [errorMessage, setErrorMessage] = useState(null)

	const validateForm = () => {
		// Basic form validations
		if (!newMember.membershipProgram) return 'Membership program is required'
		if (!newMember.fullName) return 'Full Name is required'
		if (!newMember.email) return 'Email is required'
		if (!newMember.totalAmountPaid) return 'Total Amount Paid is required'
		if (!newMember.paymentMethod) return 'Payment Method is required'

		// Check if the email already exists for the selected membership program
		const isDuplicateMember = userMemberships?.some(
			(userMembership) =>
				userMembership.user.email === newMember.email &&
				userMembership.membershipProgram._id === newMember.membershipProgram &&
				userMembership.membershipStatus === 'active'
		)

		if (isDuplicateMember) {
			return 'This email is already a member of the selected membership program'
		}

		return null // No validation errors
	}

	const handleSaveMember = () => {
		const error = validateForm()
		if (error) {
			setErrorMessage(error) // Set error message if validation fails
			return
		}

		newMember.org = org?._id
		// Dispatch action to save the new member
		dispatch(createUserMembership(newMember))
		handleCloseModal() // Close modal after saving
	}

	const properCase = (str) =>
		str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

	return (
		<>
			<OrgAdminNav />
			<Container className='my-4'>
				{!userMemberships || org?.slug !== orgSlug ? (
					<Loader />
				) : userMemberships.length > 0 ? (
					<>
						<Row className='align-items-center'>
							<Col>
								<h1>Roles</h1>
							</Col>
							{/* <Col className='text-right'>
								<Button
									className='my-3 mx-2'

									//onClick={handleAddMemberClick}
								>
									Add User Role
								</Button>
							</Col> */}
						</Row>

						<Table striped bordered hover responsive className='table-sm'>
							<thead>
								<tr>
									<th style={{ width: '25%' }}>USER</th>
									<th style={{ width: '25%' }}>EMAIL</th>
									<th style={{ width: '50%' }}>ROLE</th>
								</tr>
							</thead>
							<tbody>
								{roles.map((role, index) => (
									<tr key={index}>
										<td>{role?.user?.name}</td>
										<td>{role?.user?.email}</td>
										<td>{properCase(role?.roleType)}</td>
									</tr>
								))}
							</tbody>
						</Table>
						<Modal
							show={showModal}
							onHide={handleCloseModal}
							dialogClassName='custom-modal'
						>
							<Modal.Header closeButton>
								<Modal.Title>Issue Org Payout</Modal.Title>
							</Modal.Header>
							<Modal.Body className='modal-body-scrollable'>
								<Form>
									<Form.Group controlId='totalAmountPaid'>
										<Form.Label>Payout Amount ($)*</Form.Label>
										<Form.Control
											required
											type='number'
											placeholder='Enter payout amount'
											value={newMember.totalAmountPaid}
											onChange={(e) =>
												setNewMember({
													...newMember,
													totalAmountPaid: e.target.value,
												})
											}
										/>
									</Form.Group>
									<Form.Group controlId='notes'>
										<Form.Label>Notes</Form.Label>
										<Form.Control
											type='text'
											placeholder='Enter notes'
											value={newMember.fullName}
											onChange={(e) =>
												setNewMember({ ...newMember, fullName: e.target.value })
											}
										/>
									</Form.Group>
								</Form>
							</Modal.Body>
							<Modal.Footer>
								{errorMessage && (
									<Col xs={12}>
										<Message variant='danger'>{errorMessage}</Message>
									</Col>
								)}
								<Row className='w-100'>
									<Col className='text-right'>
										<Button variant='secondary' onClick={handleCloseModal}>
											Close
										</Button>
										<Button
											variant='primary'
											//onClick={}
										>
											Issue Payout
										</Button>
									</Col>
								</Row>
							</Modal.Footer>
						</Modal>
					</>
				) : (
					<h2>No memberships have been purchased</h2>
				)}
			</Container>
		</>
	)
}

export default OrgManageRolesScreen
