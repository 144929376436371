import React from 'react'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container } from 'react-bootstrap'

const OrgAdminNav = () => {
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const orgDetails = useSelector((state) => state.orgDetails)
	const { org } = orgDetails

	const orgSlug = org.slug

	return (
		<Navbar
			style={{ backgroundColor: org?.primaryColor ?? '#cccccc' }}
			variant='dark'
			expand='sm'
			collapseOnSelect
			className='navbar'
		>
			<Container>
				<LinkContainer
					to={`https://go.idotours.com/org/${orgSlug}/manage`}
					style={{ color: '#000', fontSize: '1rem' }}
				>
					<Navbar.Brand>{org.name}</Navbar.Brand>
				</LinkContainer>
				<Navbar.Toggle aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='ml-auto'>
						<LinkContainer to={`/org/${orgSlug}/manage`}>
							<Nav.Item>Members</Nav.Item>
						</LinkContainer>
						<LinkContainer to={`/org/${orgSlug}/manage/payouts`}>
							<Nav.Item>Payouts</Nav.Item>
						</LinkContainer>
						<LinkContainer to={`/org/${orgSlug}/manage/roles`}>
							<Nav.Item>Roles</Nav.Item>
						</LinkContainer>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}

export default OrgAdminNav
