import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../../components/Message'
import Loader from '../../../components/Loader'
import { listOrgs } from '../../../services/actions/orgActions'

const AdminOrgListScreen = ({ history, match }) => {
	const dispatch = useDispatch()

	const orgList = useSelector((state) => state.orgList)
	const { loading, error, orgs } = orgList

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!userInfo.isAdmin) {
			history.push('/login')
		}
		dispatch(listOrgs())
	}, [dispatch, history, userInfo])

	return (
		<Container className='my-3'>
			<Row className='align-items-center'>
				<Col>
					<h1>Organizations</h1>
				</Col>
			</Row>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<Table striped bordered hover responsive className='table-sm'>
					<thead>
						<tr>
							<th>NAME</th>
							<th>TYPE</th>

							<th></th>
						</tr>
					</thead>
					<tbody>
						{orgs.map((org) => (
							<tr key={org._id}>
								<td>
									<Link to={`/org/${org.slug}/`}>{org.name}</Link>
								</td>
								<td>{org.orgType}</td>

								<td>
									<LinkContainer to={`/org/${org.slug}/manage`}>
										<Button variant='light' className='btn-sm'>
											<i className='fas fa-pen-square fa-lg'></i>
										</Button>
									</LinkContainer>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			)}
		</Container>
	)
}

export default AdminOrgListScreen
