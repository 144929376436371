import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Experience = ({ order }) => {
	const { experience } = order

	return (
		<>
			{experience && (
				<LinkContainer to={`/experiences/${experience._id}`}>
					<li
						className='cards__item m-0'
						style={{
							boxShadow: '0px 0px 15px #f5f5f5',
							maxWidth: '200px', // Set fixed max width for each card
							flex: '0 0 200px', // Adjust flex basis for uniform sizing
						}}
					>
						<div className='cards__item__link'>
							<figure
								className='cards__item__pic-wrap-no-box'
								data-category={experience.type}
							>
								<img
									className='cards__item__img'
									alt='Travel Pic'
									src={experience.image}
								/>
							</figure>
							<div className='cards__item__info'>
								<h5 className='cards__item__title mb-2'>{experience.name}</h5>
							</div>
							<Button
								href={`/experiences/${experience._id}`}
								className='btn-block'
								type='button'
							>
								View Experience
							</Button>
						</div>
					</li>
				</LinkContainer>
			)}
		</>
	)
}

export default Experience
