import React from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import headerlogo from '../images/headerlogo.png'
import { logout } from '../services/actions/userActions'

const Header = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const path = location.pathname

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const userDetails = useSelector((state) => state.userDetails)
	const hasOrgRoles = userDetails?.user?.orgRoles?.length > 0

	const orgDetails = useSelector((state) => state.orgDetails)
	const { org } = orgDetails

	const logoImage = path.startsWith('/org') && org.logo ? org.logo : headerlogo

	const logoutHandler = () => {
		dispatch(logout())
	}

	return (
		<header>
			<Navbar
				style={{ backgroundColor: 'black', fontSize: '15px' }}
				variant='dark'
				expand='lg'
				collapseOnSelect
			>
				<Container>
					<a href='https://idotours.com'>
						<Navbar.Brand className='m-0 p-0'>
							<img
								alt=''
								src={logoImage}
								className='d-inline-block align-top p-0 m-0'
								style={{ maxHeight: '72px' }}
							/>
						</Navbar.Brand>
					</a>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='ml-auto'>
							{userInfo ? (
								<NavDropdown
									title={userInfo.name}
									id='username'
									className='mb-2'
								>
									<LinkContainer to='/home'>
										<NavDropdown.Item>Home</NavDropdown.Item>
									</LinkContainer>
									{hasOrgRoles && (
										<LinkContainer to='/manage/org-list'>
											<NavDropdown.Item>Manage Orgs</NavDropdown.Item>
										</LinkContainer>
									)}
									<LinkContainer to='/profile'>
										<NavDropdown.Item>Edit Profile</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to='/login'>
										<NavDropdown.Item onClick={logoutHandler}>
											Logout
										</NavDropdown.Item>
									</LinkContainer>
								</NavDropdown>
							) : (
								<LinkContainer to='/login' className='mr-3 mt-1'>
									<Nav.Link>Login</Nav.Link>
								</LinkContainer>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	)
}

export default Header
