import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, ListGroup } from 'react-bootstrap'
import Experience from '../components/MyExperiencesGallery/Experience'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import { getUserDetails } from '../services/actions/userActions'
import { listMyOrders } from '../services/actions/orderActions'
import { listMyMemberships } from '../services/actions/userMembershipActions'
import MyExperiencesGallery from '../components/MyExperiencesGallery/MyExperiencesGallery'
import MyMembershipsGallery from '../components/MyMembershipsGallery/MyMembershipsGallery'

const HomeScreen = ({ history }) => {
	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const orderListMy = useSelector((state) => state.orderListMy)
	const { orders, loading: loadingOrders = true } = orderListMy

	const userMembershipListMy = useSelector(
		(state) => state.userMembershipListMy
	)
	const { userMemberships, loading: loadingMemberships = true } =
		userMembershipListMy

	const userDetails = useSelector((state) => state.userDetails)
	const { user } = userDetails

	useEffect(() => {
		if (!userInfo) {
			history.push('/login')
		} else {
			if (!user || !user.name) {
				dispatch(getUserDetails('profile'))
			}
		}
		if (user?._id) {
			dispatch(listMyOrders())
			dispatch(listMyMemberships())
		}
	}, [dispatch, history, userInfo, user, user?._id])

	return (
		<div className='my-4'>
			<Meta title='I Do Tours | Home' />
			{loadingOrders || loadingMemberships ? (
				<Loader />
			) : (
				<Container>
					<h1>Home</h1>
					<MyExperiencesGallery
						title={
							orders?.length > 0
								? 'My Experiences'
								: 'You are not signed up for any experiences'
						}
					/>
					{userMemberships?.length > 0 ? (
						<MyMembershipsGallery
							title='My Memberships'
							memberships={userMemberships}
						/>
					) : (
						''
					)}
				</Container>
			)}
		</div>
	)
}

export default HomeScreen
