import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Membership = ({ userMembership }) => {
	const { membershipProgram: program, org } = userMembership
	return (
		<>
			{program && (
				<div
					style={{
						boxShadow: '0px 0px 15px  #f5f5f5',
					}}
					className='my-2'
				>
					<LinkContainer to={`/org/${org.slug}`}>
						<li className='cards__item m-0'>
							<div className='cards__item__link'>
								<figure
									className={'cards__item__pic-wrap'}
									data-category={program.name}
								>
									<img
										className='cards__item__img'
										alt='Travel Pic'
										src={program.image}
									/>
								</figure>
								<div className='cards__item__info py-2'>
									<h5 className='cards__item__title mb-2'>{org.name}</h5>
								</div>
							</div>
						</li>
					</LinkContainer>
					<Button href={`/org/${org.slug}`} className='btn-block' type='button'>
						View Organization
					</Button>
				</div>
			)}
		</>
	)
}

export default Membership
