import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
	Row,
	Col,
	ListGroup,
	Image,
	Card,
	Button,
	Modal,
	Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getExperienceDetails } from '../services/actions/experienceActions'
import {
	getOrderDetails,
	updateOrderDetails,
} from '../services/actions/orderActions'
import { getUserDetails } from '../services/actions/userActions'
import useExperienceLogic from '../services/hooks/useExperienceLogic'
import { pstDate } from '../services/utils/dateFormats'
import PaypalCheckoutButton from '../components/PaypalCheckoutButton'
import './OrderScreen.css'

const OrderScreen = ({ match, history }) => {
	const dispatch = useDispatch()
	const orderId = match.params.id

	// Logged in user details from state
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	// User details of current user profile from state
	const userDetails = useSelector((state) => state.userDetails)
	const { user } = userDetails

	// Order details from state
	const orderDetails = useSelector((state) => state.orderDetails)
	const { order, loading, error } = orderDetails

	useEffect(() => {
		if (!userInfo) {
			history.push('/login')
		} else if (!user?._id) {
			dispatch(getUserDetails('profile'))
		}
	}, [history, user, userInfo])

	useEffect(() => {
		if (order?._id !== orderId) {
			dispatch(getOrderDetails(orderId))
		}
	}, [order, orderId, dispatch])

	useEffect(() => {
		if (order) {
			dispatch(getExperienceDetails(order.experience))
		}
	}, [order, dispatch])

	// Experience details from state
	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { experience } = experienceDetails
	const experienceId = experience?._id

	const [copyButton, setCopyButton] = useState('Click to Copy Trip URL')

	const [showModal, setShowModal] = useState(false)
	const [selectedTripOption, setSelectedTripOption] = useState(null)

	// Order pay details from state
	const orderPay = useSelector((state) => state.orderPay)
	const { error: errorPay } = orderPay

	// Get trip option information by matching to order
	const tripOptionsMap = order?.addOns.reduce((map, addOn) => {
		map[addOn.tripOptionId] = addOn
		return map
	}, {})

	const tripOptionsOnOrder = experience?.tripOption
		?.filter((option) => tripOptionsMap?.hasOwnProperty(option._id))
		.map((option) => ({
			...option,
			...tripOptionsMap[option._id],
		}))

	//Modal handling
	const handleShowModal = (tripOption) => {
		setSelectedTripOption(tripOption)
		setShowModal(true)
	}

	const handleCloseModal = () => {
		setShowModal(false)
		setSelectedTripOption(null)
	}
	// Handle trip option removal
	const handleConfirmRemove = () => {
		if (order.orderStatus === 'open') {
			const addOnToRemove = order.addOns.find((addOn) => {
				return addOn.tripOptionId === selectedTripOption.tripOptionId
			})

			const taxToRemove = parseFloat(
				(
					parseFloat(experience.taxRate) * parseFloat(addOnToRemove.price)
				).toFixed(2)
			)

			order.taxPrice = parseFloat(
				(parseFloat(order.taxPrice) - parseFloat(taxToRemove)).toFixed(2)
			)

			order.addOnsPrice = parseFloat(
				(
					parseFloat(order.addOnsPrice) - parseFloat(addOnToRemove.price)
				).toFixed(2)
			)

			const newTotalOrderAmount = parseFloat(
				(
					parseFloat(order.totalPrice) -
					parseFloat(taxToRemove) -
					parseFloat(addOnToRemove.price)
				).toFixed(2)
			)

			order.totalPrice = newTotalOrderAmount
			order.totalAmountRemaining = newTotalOrderAmount
			if (orderDetails.paymentStructure === 'Pay deposit first') {
			} else {
				order.firstPaymentPrice = newTotalOrderAmount
			}

			const updatedAddOns = order.addOns.filter((addOn) => {
				return addOn.tripOptionId !== selectedTripOption.tripOptionId
			})

			order.addOns = updatedAddOns

			dispatch(updateOrderDetails(order._id, order))
		} else {
			alert('Cannot alter an order that has payments')
		}
		handleCloseModal()
	}

	const owedToday =
		!loading &&
		(order?.orderStatus === 'Paid in full'
			? 0
			: order.orderStatus === 'Deposit paid'
			? order.totalAmountRemaining
			: order.firstPaymentPrice)

	const { enableButton } = useExperienceLogic(experience, user)

	const product = {
		description: experience?.name,
		price: owedToday,
	}

	return loading || !order || !experienceId ? (
		<Loader />
	) : error ? (
		<Message variant='danger'>{error}</Message>
	) : errorPay ? (
		<Message variant='danger'>
			We recieved your payment but had trouble recording it. Please contact I Do
			Tours support (info@idotours.com). Do not try to pay again.
		</Message>
	) : (
		<Container>
			<div className='my-4'>
				<h1>Order</h1>
				<Row>
					<Col md={8}>
						{order.orderStatus !== 'open' ? (
							<Message variant='success'> {order.orderStatus}</Message>
						) : (
							<Message variant='primary'>Not Paid</Message>
						)}
						<ListGroup variant='flush'>
							<ListGroup.Item>
								<h2>Traveler Info</h2>
								<p>
									<strong>Name: </strong> {order.user.name}
								</p>
								<p>
									<strong>Email: </strong>{' '}
									<a href={`mailto:${order.user.email}`}>{order.user.email}</a>
								</p>
							</ListGroup.Item>
							<ListGroup.Item>
								<h2>Experience</h2>
								{order.length === 0 ? (
									<Message>
										You are not booking any experience right now
									</Message>
								) : (
									<ListGroup variant='flush'>
										<ListGroup.Item>
											<Row>
												<Col md={1} className='p-0'>
													<Image
														src={experience.image}
														alt={experience.name}
														fluid
														rounded
													/>
												</Col>
												<Col>
													<Link to={`/experiences/${experience._id}`}>
														{experience.name}
													</Link>
												</Col>
												<Col md={4}></Col>
											</Row>
										</ListGroup.Item>
									</ListGroup>
								)}
							</ListGroup.Item>
							<ListGroup.Item>
								<h2>Trip Options</h2>
								{experience?.tripOption.length === 0 ? (
									''
								) : tripOptionsOnOrder?.length === 0 ? (
									'No trip options added to order'
								) : (
									<ListGroup variant='flush'>
										<ListGroup.Item>
											{tripOptionsOnOrder.map((tripOption) => (
												<Row
													className='align-items-center'
													key={tripOption.tripOptionId}
												>
													{order.orderStatus === 'open' ? (
														<Col style={{ maxWidth: '20px' }}>
															<i
																className='icon-hover fas fa-times fa-md '
																onClick={() => handleShowModal(tripOption)}
															/>
														</Col>
													) : (
														''
													)}
													<Col xs={4} sm={2} md={1} className='p-0'>
														<Image
															src={tripOption.image}
															alt={tripOption.description}
															fluid
															rounded
														/>
													</Col>
													<Col>
														<div className='d-flex align-items-center'>
															{tripOption.title}
															{tripOption.size && (
																<span className='badge m-2'>
																	{tripOption.size}
																</span>
															)}
														</div>
													</Col>
													<Col md={4}></Col>
												</Row>
											))}
										</ListGroup.Item>
									</ListGroup>
								)}
							</ListGroup.Item>
							<ListGroup.Item>
								<h2>Payment Structure</h2>
								<p>
									<strong>Structure: </strong>
									{order.paymentStructure}
								</p>
								{/* Deposit only logic */}
								{order.paymentStructure === 'Pay deposit first' ? (
									<>
										<p>
											<strong>Deposit Amount: </strong>$
											{order.firstPaymentPrice.toFixed(2)}
										</p>
										<p>
											<strong>Amount Owed After Deposit: </strong>$
											{(order.totalPrice - order.firstPaymentPrice).toFixed(2)}
										</p>
										<p>
											{order?.finalPaymentDueDate && (
												<div>
													<strong>Final Payment Due: </strong>
													{pstDate(order.finalPaymentDueDate).substring(0, 10)}
												</div>
											)}
										</p>
									</>
								) : order.paymentStructure === 'Pay in full' ? (
									''
								) : (
									'Error loading payment structure.'
								)}
								{/* End deposit only logic */}
								<strong> </strong>
								<p></p>
							</ListGroup.Item>
							<ListGroup.Item>
								<h2>Terms and Conditions</h2>
								<div style={{ maxHeight: '115px', overflow: 'scroll' }}>
									<p>{experience?.termsAndConditionsNote}</p>
								</div>
							</ListGroup.Item>
						</ListGroup>
					</Col>
					<Col md={4}>
						<Card>
							<ListGroup>
								<ListGroup.Item>
									<h2>Order Summary</h2>
								</ListGroup.Item>
								<ListGroup.Item>
									<Row>
										<Col>Experience Price</Col>
										<Col>
											$
											{experience.fullPriceWithoutTax &&
												experience.fullPriceWithoutTax.toFixed(2)}
										</Col>
									</Row>
								</ListGroup.Item>
								{experience.tripOption && experience.tripOption.length > 0 ? (
									<ListGroup.Item>
										<Row>
											<Col>Add-Ons</Col>
											<Col>${order.addOnsPrice.toFixed(2)}</Col>
										</Row>
									</ListGroup.Item>
								) : (
									''
								)}
								{order.taxPrice > 0 ? (
									<ListGroup.Item>
										<Row>
											<Col>Taxes and Fees</Col>
											<Col>${order?.taxPrice?.toFixed(2)}</Col>
										</Row>
									</ListGroup.Item>
								) : (
									''
								)}
								<ListGroup.Item className='font-weight-bold'>
									<Row>
										<Col>Total</Col>
										<Col>${order.totalPrice.toFixed(2)}</Col>
									</Row>
								</ListGroup.Item>
								{order.paymentStructure === 'Pay deposit first' && (
									<ListGroup.Item className='font-weight-bold'>
										<Row>
											<Col>Amount Paid</Col>
											<Col>${order.totalAmountPaid.toFixed(2)}</Col>
										</Row>
									</ListGroup.Item>
								)}
								<ListGroup.Item className='font-weight-bold'>
									<Row>
										<Col>
											{order.totalAmountPaid > 0 ? 'Amount Owed' : 'Owed Today'}
										</Col>
										<Col>${owedToday.toFixed(2)}</Col>
									</Row>
								</ListGroup.Item>
								{order.orderStatus !== 'Paid in full' && (
									<ListGroup.Item>
										{enableButton ? (
											<PaypalCheckoutButton
												product={product}
												orderId={orderId}
											/>
										) : (
											'Not available for purchase'
										)}
									</ListGroup.Item>
								)}
							</ListGroup>
						</Card>
						<br />
						<Card>
							<ListGroup>
								<ListGroup.Item>
									<h2>Share URL</h2>
								</ListGroup.Item>
								<ListGroup.Item>
									<Row>
										<Col>
											<Button
												onClick={() => {
													if (navigator.share) {
														navigator
															.share({
																title: experience.name,
																text: `Sign up now for ${experience.name}`,
																url: `${window.location.origin}/experiences/${experience._id}`,
															})
															.catch((error) => {})
													} else {
														navigator.clipboard
															.writeText(
																`${window.location.origin}/experiences/${experience._id}`
															)
															.then(setCopyButton('Copied!'))
													}
												}}
												className='btn-block'
												type='button'
											>
												<i className='fas fa-link fa-lg' />
												{'  ' + copyButton}
											</Button>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
						</Card>
						<ListGroup>
							<br />
							{/* <Row>
							<Col className='mx-3'>
								<Button
									onClick={() => console.log('')}
									className='btn-block'
									type='button'
									variant='danger'
								>
									<i className='fas fa-trash fa-lg' />
									{'  ' + 'DELETE YOUR ORDER'}
								</Button>
							</Col>
						</Row> */}
						</ListGroup>
					</Col>
				</Row>

				<Modal show={showModal} onHide={handleCloseModal}>
					<Modal.Header closeButton>
						<Modal.Title>Confirm Removal</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						Are you sure you want to remove this trip option?
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={handleCloseModal}>
							Cancel
						</Button>
						<Button variant='primary' onClick={handleConfirmRemove}>
							Confirm
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</Container>
	)
}

export default OrderScreen
