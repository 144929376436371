import React from 'react'

function CardItem(props) {
	return (
		<>
			<li className='cards__item'>
				<div className='cards__item__link'>
					<figure
						className={
							props.type === 'itinerary'
								? 'cards__item__pic-wrap'
								: 'cards__item__pic-wrap-no-dc'
						}
						data-category={props.label}
					>
						<img
							className='cards__item__img'
							alt='Travel Pic'
							src={props.src}
						/>
					</figure>
					<div className='cards__item__info'>
						<h5 className='cards__item__title'>{props.title}</h5>
						<p className='cards__item__text'>{props.desc}</p>
					</div>
				</div>
			</li>
		</>
	)
}

export default CardItem
