import { useState } from 'react'
import { useSelector } from 'react-redux'
import Experience from './Experience'
import {
	Row,
	Col,
	ListGroup,
	Container,
	ToggleButton,
	ButtonGroup,
} from 'react-bootstrap'
import Loader from '../Loader'

const MyExperiencesGallery = ({ title }) => {
	const orderListMy = useSelector((state) => state.orderListMy)
	const userDetails = useSelector((state) => state.userDetails)
	const [selectedValue, setSelectedValue] = useState('CURRENT') // Initial selected option

	const { orders, loading = true } = orderListMy
	const { user } = userDetails

	const options = [
		{ name: 'PAST', value: 'PAST' },
		{ name: 'CURRENT', value: 'CURRENT' },
	]

	return (
		<div>
			<Container>
				<Row>
					<Col md={12} className='gallery-container'>
						<ListGroup variant='flush'>
							{loading ? (
								<Loader />
							) : orders ? (
								<>
									<ListGroup.Item>
										<h2>{title}</h2>
										<div className='cards__wrapper'>
											<ul className='cards__items'>
												{orders?.map((order) => (
													<Col
														key={order._id}
														xs={10}
														sm={8}
														md={6}
														lg={3}
														xl={3}
													>
														<Experience order={order} user={user} />
													</Col>
												))}
											</ul>
										</div>
									</ListGroup.Item>
									{/* <Container fluid className='d-flex justify-content-center'>
										<Row>
											<Col className='text-center'>
												<ButtonGroup toggle className='d-inline-flex'>
													{options.map((option, idx) => (
														<ToggleButton
															key={idx}
															type='radio'
															variant='outline-primary'
															name='radio'
															className='p-1'
															value={option.value}
															checked={selectedValue === option.value}
															onChange={(e) =>
																setSelectedValue(e.currentTarget.value)
															}
															style={{ width: '150px' }} // Set each button to be 50px wide
														>
															{option.name}
														</ToggleButton>
													))}
												</ButtonGroup>
											</Col>
										</Row>
									</Container> */}
								</>
							) : (
								''
							)}
						</ListGroup>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

MyExperiencesGallery.defaultProps = {
	title: 'My Experiences',
}

export default MyExperiencesGallery
