import {
	USERMEMBERSHIP_CREATE_REQUEST,
	USERMEMBERSHIP_CREATE_SUCCESS,
	USERMEMBERSHIP_CREATE_FAIL,
	USERMEMBERSHIP_CREATE_RESET,
	USERMEMBERSHIP_DETAILS_REQUEST,
	USERMEMBERSHIP_DETAILS_SUCCESS,
	USERMEMBERSHIP_DETAILS_FAIL,
	USERMEMBERSHIP_PAY_REQUEST,
	USERMEMBERSHIP_PAY_SUCCESS,
	USERMEMBERSHIP_PAY_FAIL,
	USERMEMBERSHIP_PAY_RESET,
	USERMEMBERSHIP_LIST_MY_REQUEST,
	USERMEMBERSHIP_LIST_MY_SUCCESS,
	USERMEMBERSHIP_LIST_MY_FAIL,
	USERMEMBERSHIP_LIST_MY_RESET,
	USERMEMBERSHIP_UPDATE_REQUEST,
	USERMEMBERSHIP_UPDATE_SUCCESS,
	USERMEMBERSHIP_UPDATE_FAIL,
	USERMEMBERSHIP_LIST_ORG_REQUEST,
	USERMEMBERSHIP_LIST_ORG_SUCCESS,
	USERMEMBERSHIP_LIST_ORG_FAIL,
	USERMEMBERSHIP_LIST_ORG_RESET,
} from '../constants/userMembershipConstants'

export const userMembershipCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case USERMEMBERSHIP_CREATE_REQUEST:
			return {
				loading: true,
			}
		case USERMEMBERSHIP_CREATE_SUCCESS:
			return {
				loading: false,
				success: true,
				userMembership: action.payload,
			}
		case USERMEMBERSHIP_CREATE_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		case USERMEMBERSHIP_CREATE_RESET:
			return {}

		default:
			return state
	}
}

export const userMembershipDetailsReducer = (
	state = { loading: true, userMembership: {} },
	action
) => {
	switch (action.type) {
		case USERMEMBERSHIP_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
			}
		case USERMEMBERSHIP_DETAILS_SUCCESS:
			return {
				loading: false,
				userMembership: action.payload,
			}
		case USERMEMBERSHIP_DETAILS_FAIL:
			return {
				loading: false,
				error: action.payload,
			}

		default:
			return state
	}
}

export const userMembershipPayReducer = (state = {}, action) => {
	switch (action.type) {
		case USERMEMBERSHIP_PAY_REQUEST:
			return {
				loading: true,
			}
		case USERMEMBERSHIP_PAY_SUCCESS:
			return {
				loading: false,
				success: true,
			}
		case USERMEMBERSHIP_PAY_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		case USERMEMBERSHIP_PAY_RESET:
			return {}

		default:
			return state
	}
}

export const userMembershipListMyReducer = (
	state = { userMemberships: [] },
	action
) => {
	switch (action.type) {
		case USERMEMBERSHIP_LIST_MY_REQUEST:
			return {
				loading: true,
			}
		case USERMEMBERSHIP_LIST_MY_SUCCESS:
			return {
				loading: false,
				userMemberships: action.payload,
			}
		case USERMEMBERSHIP_LIST_MY_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		case USERMEMBERSHIP_LIST_MY_RESET:
			return { userMemberships: [] }

		default:
			return state
	}
}

export const userMembershipUpdateReducer = (state = {}, action) => {
	switch (action.type) {
		case USERMEMBERSHIP_UPDATE_REQUEST:
			return { loading: true }
		case USERMEMBERSHIP_UPDATE_SUCCESS:
			return { loading: false, success: true }
		case USERMEMBERSHIP_UPDATE_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const userMembershipListOrgReducer = (
	state = { userMemberships: [] },
	action
) => {
	switch (action.type) {
		case USERMEMBERSHIP_LIST_ORG_REQUEST:
			return {
				loading: true,
			}
		case USERMEMBERSHIP_LIST_ORG_SUCCESS:
			return {
				loading: false,
				userMemberships: action.payload,
			}
		case USERMEMBERSHIP_LIST_ORG_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		case USERMEMBERSHIP_LIST_ORG_RESET:
			return { userMemberships: [] }

		default:
			return state
	}
}
